var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.atual.header,"search":_vm.search,"items":_vm.atual.getter,"hide-default-footer":"","no-data-text":"Nenhum Fornecedor Cadastrado"},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green darken-4"},on:{"click":() => {
            _vm.visibleItem(item);
          }}},[_vm._v("mdi-eye")]),(_vm.component.permission.Editar)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"yellow darken-4"},on:{"click":() => {
            _vm.edit_item(item);
          }}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.component.permission.Excluir)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red darken-4"},on:{"click":() => {
            _vm.deleteItem(item);
          }}},[_vm._v("mdi-delete")]):_vm._e()]}}],null,true)}),_c('Delete',{attrs:{"component":this}}),_c('div',[_c('v-divider',{staticClass:"mt-6"}),_c('v-pagination',{staticClass:"mb-3",attrs:{"length":_vm.atualTotal,"color":"success","total-visible":10},model:{value:(_vm.atualCurrent),callback:function ($$v) {_vm.atualCurrent=$$v},expression:"atualCurrent"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }